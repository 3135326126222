import React from 'react';
import NoMatch from '@nm-utils-lib-web/no-match';

import Layout from '../Layout/Layout';

const NoMatch404 = (props) => {
  return (
    <Layout>
      <NoMatch {...props} translationNamespace="auth.page404" />
    </Layout>
  );
};

export default NoMatch404;
