import noMatchLanguage from '@nm-utils-lib-web/no-match/languages/nutmeg-gbr-bigbear.json';

export default {
  auth: {
    ...noMatchLanguage,
    common: {
      appTitle: 'TBD',
    },
  },
};
