import React from 'react';
import { getOrganisation, isIPBGBR } from '@nm-utils-lib-web/organisations';
import { useAuth, useOrganisationParameter } from '@nm-utils-lib-web/authentication';
import { getAuth0OrganisationId } from '@nm-utils-lib-web/authentication/src/utils';
import Loading from '@nutkit/component-loading';
import { pageView } from '@nm-utils-lib-web/analytics';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';

import { setAuthCookies, pollOneTrustAcceptedCookie, getIPBConnectionParam } from '../../helpers';
import { AUTH0_ORGANISATIONS, ROUTES } from '../../constants';
import { useQueryParamsOrgRedirect, useQueryParamsReturnToRedirect } from '../../hooks';

const CustomerLogin = () => {
  // This is a long lived feature flag that can be toggled off if OneTrust goes down.
  // It skips the OneTrust check on the login page but allows logins to continue. Do not remove.
  const isOneTrustEnabled = useFlag(Flags.ONETRUST_NM_AUTH);
  const { loginWithRedirect } = useAuth();
  const { redirectURL } = useQueryParamsOrgRedirect();
  const { returnTo } = useQueryParamsReturnToRedirect();
  const shouldUseOrganisationParam = useOrganisationParameter();
  const organisation = getOrganisation();
  const authHost = AUTH0_ORGANISATIONS.AUTH_HOSTS[organisation];

  React.useEffect(() => {
    (async () => {
      await pollOneTrustAcceptedCookie(isOneTrustEnabled);

      await pageView({
        name: 'Login_Page_Viewed',
        options: {
          category: 'Login',
        },
      });

      setAuthCookies();

      if (returnTo) {
        window.location.replace(returnTo);
      } else if (redirectURL) {
        window.location.replace(redirectURL);
      } else {
        loginWithRedirect({
          appState: {},
          ...(isIPBGBR(organisation) && {
            connection: getIPBConnectionParam(),
          }),
          authorizationParams: {
            redirect_uri: `${window.NutmegConfig[authHost]}${ROUTES.CUSTOMER_CALLBACK}`,
            ...(shouldUseOrganisationParam && { organization: getAuth0OrganisationId() }),
          },
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default CustomerLogin;
