import { ORGANISATIONS } from '@nm-utils-lib-web/organisations/constants';

export const NUTMEG = 'nutmeg';
export const CHASE = 'chase-gbr';
export const IPB_GBR = 'ipb-gbr';

export const AUTH_HOSTS = {
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: 'AUTH_HOST_NUTMEG',
  [ORGANISATIONS.CHASE_GBR]: 'AUTH_HOST_CHASE_GBR',
  [ORGANISATIONS.IPB_GBR]: 'AUTH_HOST_IPB_GBR',
};
