import { getIPBDevelopmentConnectionParam } from '@nm-utils-lib-web/authentication/src/utils';
import { getEnvironment, environments } from '@nm-utils-lib-web/environments';
import { auth0 } from '@nm-utils-lib-web/authentication';

const getIPBConnectionParam = () => {
  const environment = getEnvironment();

  return [environments.PROD, environments.BETA].includes(environment)
    ? auth0.connections.IPB_PROD
    : getIPBDevelopmentConnectionParam();
};

export default getIPBConnectionParam;
