import StatusCodes from 'http-status-codes';

import errorHandler from './errorHandler';
import AnnualReviewService from './AnnualReviewService';

const getAnnualReviewState = async customerUuid => {
  try {
    const { data } = await AnnualReviewService.get(`/customers/${customerUuid}/annual_review_state`);

    return data;
  } catch (error) {
    /*
    we're navigating to the nm-auth /callback route twice quickly which means we're sending this request twice,
    the first request gets cancelled by the browser, Safari continues the execution of this script and reports the
    cancelled request as an error to Sentry, other browsers don't do this, we're trying to detect the cancelled reuqest
    here to ignore it instead of having it reported to Sentry
    */
    const didRequestGetCancelled =
      error.isAxiosError &&
      error.request.response === '' &&
      error.request.responseType === '' &&
      error.request.status === 0 &&
      error.request.readyState === 4 &&
      error.response === undefined;

    if (didRequestGetCancelled) {
      return {};
    }
    if (error?.response?.status === StatusCodes.NOT_FOUND) {
      return {};
    }
    errorHandler(error, 'Error getting customer annual review state');
  }
};

export default getAnnualReviewState;
