import React, { useEffect } from 'react';
import fetchImage, { imageSizes } from '@nm-marketing/prismic-utils';
import { Row, Col, Container } from '@nutkit/component-grid';
import Align from '@nutkit/component-align';
import Panel from '@nutkit/component-panel';
import { LayoutCenteredContent } from '@nutkit/layouts';
import { Text, Heading } from '@nutkit/component-text';
import Logo, { IconMail, IconPhone, OrganisationLogo } from '@nutkit/component-icon';
import Inline from '@nutkit/component-inline';
import Link from '@nutkit/component-link';
import Bold from '@nutkit/component-bold';
import Section from '@nutkit/component-section';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { eraseSLOCookie } from '@nm-utils-lib-web/authentication';

import Header from '../Header';
import Footer from '../Footer';

import './LogoutSuccess.scss';

const TRANSLATION_NAMESPACE = 'auth.common.logoutSuccess';

const LogoutSuccess = () => {
  const { t } = useTranslation();
  const emailCopy = t(`${TRANSLATION_NAMESPACE}.email`);
  const phoneCopy = t(`${TRANSLATION_NAMESPACE}.phone`);
  const imageSource = fetchImage(
    'marketingwebsite',
    'Z5OeZpbqstJ990nL_76946dcbc76e2fb0339bf6f671c3b5ff',
    imageSizes.AUTO,
    'jpg',
  );

  useEffect(() => {
    eraseSLOCookie();
  }, []);

  return (
    <Section>
      <div className="logout-success__background" style={{ backgroundImage: `url('${imageSource}')` }} />
      <Header />
      <LayoutCenteredContent>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
              <Panel>
                <Heading>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
                <hr />
                <Text>{t(`${TRANSLATION_NAMESPACE}.closeTab`)}</Text>
                <Panel title={<Heading level="2">{t(`${TRANSLATION_NAMESPACE}.contact`)}</Heading>}>
                  <Text>
                    <Trans components={[<Bold>bold content</Bold>]} i18nKey={`${TRANSLATION_NAMESPACE}.needHelp`} />
                  </Text>
                  <Col lg={{ size: 10 }}>
                    <Align justifyContent="space-between" flexWrap="wrap">
                      <Inline space="sm">
                        <Logo size="md" component={IconPhone} />
                        <Link href={`tel:${phoneCopy.replaceAll(' ', '')}`}>{phoneCopy}</Link>
                      </Inline>

                      <Inline space="sm">
                        <Logo size="md" component={IconMail} />
                        <Link href={`mailto:${emailCopy}`}>{emailCopy}</Link>
                      </Inline>
                    </Align>
                  </Col>
                </Panel>

                <Inline space="lg" alignItems="center">
                  <Logo size="xxxl" component={OrganisationLogo} />
                  <div className="logout-success__vertical-line" />
                  <Logo size="xxxl" extended component={OrganisationLogo} />
                </Inline>
              </Panel>
            </Col>
          </Row>
        </Container>
      </LayoutCenteredContent>
      <Footer />
    </Section>
  );
};

export default LogoutSuccess;
