import cookies from 'browser-cookies';
import {
  IMPERSONATION_MODE,
  DASHBOARD_SLO_COOKIE,
  SIGNUP_SLO_COOKIE
} from '@nm-utils-lib-web/authentication/src/constants/featureDetection';
import { getCookieDomain } from '@nm-utils-lib-web/cookies';
import { eraseAuth0IsAuthenticatedCookie } from '@nm-utils-lib-web/authentication';
import { eraseIPBConnectionCookie } from '@nm-utils-lib-web/authentication/src/utils';
import { ACCESS_TOKEN } from '@nm-utils-lib-web/authentication/src/constants/tokenConstants';

import { NUTMEG_CLIENT_SESSION_COOKIE_NAME } from '../constants/cookies';

const eraseAuthCookies = (domain = getCookieDomain()) => {
  eraseAuth0IsAuthenticatedCookie(domain);
  eraseIPBConnectionCookie(domain);

  // TODO: Use removeSessionId() from utils-lib-web/tracking once PAR-1225 is complete
  // https://hungryfinance.jira.com/browse/PAR-1225
  cookies.erase('session_id', { domain });

  // TODO: Remove access_token removal when Ruby UL SDK is complete.
  cookies.erase(ACCESS_TOKEN, { domain });
  cookies.erase(IMPERSONATION_MODE, { domain });
  cookies.erase(NUTMEG_CLIENT_SESSION_COOKIE_NAME, { domain });
  cookies.erase(DASHBOARD_SLO_COOKIE, { domain });
  cookies.erase(SIGNUP_SLO_COOKIE, { domain });
};

export default eraseAuthCookies;
