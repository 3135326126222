import React from 'react';
import { useAuth, setSLOCookie } from '@nm-utils-lib-web/authentication';
import { isIPBGBR } from '@nm-utils-lib-web/organisations';
import Loading from '@nutkit/component-loading';

import { eraseAuthCookies } from '../../helpers';
import { useAppLogout } from '../../hooks';
import { IPB_LOGOUT_SUCCESS } from '../../constants/routes';

const Logout = () => {
  const { logout } = useAuth();
  const { allAppsLoggedOut } = useAppLogout();

  React.useEffect(() => {
    if (allAppsLoggedOut) {
      setSLOCookie();
      eraseAuthCookies();
      logout({
        logoutParams: {
          returnTo: `${window.NutmegConfig.NM_AUTH_WEB}${isIPBGBR() ? IPB_LOGOUT_SUCCESS : ''}`
        }
      });
    }
  }, [logout, allAppsLoggedOut]);

  return <Loading data-qa="logout-loading" />;
};

export default Logout;
