import cookies from 'browser-cookies';
import { IMPERSONATION_MODE } from '@nm-utils-lib-web/authentication/src/constants/featureDetection';
import { setIPBConnectionCookie } from '@nm-utils-lib-web/authentication/src/utils';
import { getCookieDomain } from '@nm-utils-lib-web/cookies';

import { CONNECTIONS } from '../constants';

import eraseAuthCookies from './eraseAuthCookies';

const setAuthCookies = impersonation => {
  const domain = getCookieDomain();

  if (!impersonation) {
    eraseAuthCookies(domain);
  }

  // Remove this once all apps have upgraded to @nm-utils-lib-web/authentication >=7.32.0
  cookies.set('nm-auth-web__use-auth0', 'true', { domain });

  setIPBConnectionCookie(domain);

  if (impersonation) {
    cookies.set(IMPERSONATION_MODE, CONNECTIONS.CHASE_SSO, { domain });
  }
};

export default setAuthCookies;
